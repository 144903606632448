<template>
  <div>
    <template v-if="useSwiperPlugin && isSwiperLoaded">
      <component
        :is="swiperComponent.main.value"
        v-bind="swiperOption"
        :modules="[
          swiperModule.navigation.value,
          swiperModule.pagination.value,
        ]"
        class="swiper"
        :class="wrapperClass"
      >
        <slot :slide="swiperComponent.slide.value"></slot>
      </component>
    </template>
    <template v-else>
      <div
        class="hide-scroll relative flex overflow-x-scroll"
        :class="wrapperClass"
      >
        <slot :slide="'div'"></slot>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch } from "vue";
import useSwiper from "~/composables/useSwiper";
import useClientTypeStore from "~/stores/clientType";

const clientTypeStore = useClientTypeStore();
const { isSwiperLoaded, swiperComponent, swiperModule, loadSwiper } =
  useSwiper();
const props = defineProps<{
  swiper?: boolean;
  swiperOption?: Record<string, any>;
  wrapperClass?: string;
}>();
const useSwiperPlugin = computed(() => {
  if (props.swiper) {
    return true;
  }
  return clientTypeStore.isDesktop;
});

watch(
  useSwiperPlugin,
  (newVal) => {
    if (newVal) {
      loadSwiper();
    }
  },
  { immediate: true }
);
</script>
